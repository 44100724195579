<template>
	<div>
    <Environment v-if="environment != 'production'"/>
    <Toast />
    <ConfirmDialog />
    <cmsHeader />
		<div class="container">
			<div class="formcontainer">
				<h1>Blogs : List</h1>
				<p>
					Below is a list of <b>Blog</b> posts sorted by date. To Add a
					blog, click <b>ADD BLOG</b>. To edit, click the
					title. To delete click
					<span class="glyphicon glyphicon-remove"></span>.
				</p>
				<br />

				<div class="rightfilter">
					<router-link to="/cms/blog/add" class="btn-orange btn btn-default">
						ADD BLOG</router-link
					>
				</div>

				<div class="tablestyle">
					<table>
						<thead>
							<tr>
								<td>
									Title
								</td>

								<td width="" align="center">
									Date
								</td>
								<td width="" align="center">
									Status
								</td>

								<td width="" align="center">
									Delete
								</td>
							</tr>
						</thead>
						<tbody>

							<tr 
                v-for="(resource, index) in resources" :key="index">
								<td>
									<a @click="editBlog(resource)">{{ resource.title }}</a>
								</td>
								<td>
									<span class="resource-date">{{ resource.date }}</span>
								</td>
								<td>
										<span class="resource-status">{{resource.status}}</span>
								</td>
								<td>
										<a
											style="cursor:pointer;"
											@click="confirmDelete(resource)"
											><span class="glyphicon glyphicon-remove resource-delete"></span
										></a>
								</td>
							</tr>

						</tbody>
					</table>
				</div>
			</div>
		</div>
    <br>
    <cmsFooter/>
	</div>
</template>

<script>
// Components
import cmsHeader from '../../../components/cms/cmsHeader.vue'
import cmsFooter from '../../../components/cms/cmsFooter.vue'

// Primevue
import ConfirmDialog from 'primevue/confirmdialog'
import { useConfirm } from 'primevue/useconfirm'

// API services
import CmsServices from '../../../services/CmsServices.js'
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'primevue/usetoast'

	export default {
    name: 'bloglist',
    components: {
      cmsHeader,
      ConfirmDialog,
      cmsFooter
    },
		setup() {
      const router = useRouter()
      const toast = useToast()
      const confirm = useConfirm()
      const environment = process.env.VUE_APP_ENVIRONMENT;
      var resources = ref();

      onMounted(() => {
        CmsServices.getResources()
        .then((response) => {
          if(response.status != 200) {
            toast.add({severity: 'info', summary: 'Information', detail: response.message, life: 5000})
          } else {
            resources.value = response.data
          }
        })
        .catch((error) => {
          toast.add({severity: 'error', summary: error.response.statusText, detail: "Could not retrieve list of postings!", life: 5000})
        })
      })

      const editBlog = (resource) => {
        const rID = resource.id
        router.push({ name:"cmsBlogEdit", query:{ rID: rID }})
      }

      const deletePost = (resource) => {
        CmsServices.deleteResource(resource.id)
        .then((response) => {
          if(response.status != 200) {
            toast.add({severity: 'info', summary: 'Information', detail: response.message, life: 5000})
          } else {
            toast.add({severity: 'success', summary: 'Success', detail: "Blog has been deleted!", life: 5000})
            resources.value.splice(resources.value.indexOf(resource),1)
          }
        })
        .catch((error) => {
          toast.add({severity: 'error', summary: error.response.statusText, detail: error.response.data.reason, life: 5000})
        })
      }

      const confirmDelete = (resource) => {
        confirm.require({
          message: 'Do you want to delete this blog post?',
          header: 'Delete Confirmation',
          icon: 'pi pi-info-circle',
          acceptClass: 'p-button-danger',
          accept: () => {
            deletePost(resource)
          }
        })
      }

      return {
        environment,
        resources,
        editBlog,
        confirmDelete
      }
    }
	};
</script>

<style lang="scss" scoped>
.resource-date {
  display: flex;
  justify-content: center;
  text-align: center;
}
.resource-status {
  display: flex;
  justify-content: center;
  text-align: center;
}
.resource-delete {
  display: flex;
  justify-content: center;
  text-align: center;
}
</style>
